<template>
  <div>
    <div class="grid1">
      <div class="grid1-row-1-col-1 site-condition-cards">
        <div class="availability-card plot-figure-container">
          <HealthWidget
            :nCriticalIssues="nOpenReliablilityIssues"
            :name="'OVERALL RELIABILITY HEALTH'"
            :loading="loading.getSummary"
          ></HealthWidget>
        </div>
      </div>
      <div class="grid1-row-1-col-2 site-condition-cards">
        <div class="performance-card plot-figure-container">
          <HealthWidget
            :nCriticalIssues="nOpenPerformanceIssues"
            :name="'OVERALL PERFORMANCE'"
            :loading="loading.getSummary"
          ></HealthWidget>
        </div>
      </div>
      <div class="grid1-row-1-col-3">
        <div class="ml-1 at-a-glance-container">
          <div
            class="d-flex justify-center align-center at-a-glance-container__spinner"
            v-if="loading.getSummary"
          >
            <v-progress-circular
              :size="40"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <AtAGlance :page="'org'">
            <template v-slot:content>
              <div class="mx-1">
                <div class="mt-2 orientation-header">
                  <p class="mb-2">TOTAL CAPACITY</p>
                </div>
                <div class="d-flex justify-space-between align-items-center">
                  <p class="content-body">{{ totalCapacityMw }} MW</p>
                  <div class="mr-2">
                    <p class="ma-0">{{ nSites }} sites</p>
                    <p>{{ nTurbines }} turbines</p>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div class="mx-1 mt-1">
                <div class="d-flex justify-space-between align-baseline">
                  <div class="orientation-header">
                    <p>TOTAL OPPORTUNITY</p>
                  </div>
                  <div
                    class="d-flex flex-row align-baseline ma-0 pa-0 switch-labels"
                  >
                    <label class="mr-3">Cumulative</label>
                    <v-switch
                      inset
                      hide-details
                      dense
                      v-model="lossGainToggle"
                      class="toggle ma-0 pa-0"
                    ></v-switch>
                    <label>Annual</label>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-end">
                  <h4 class="content-body mb-0">${{ aepPotentialUsd }}/year</h4>
                  <div :class="impactColor" class="mr-4">
                    <div v-if="lossGainToggle">
                      <div>+ ${{ aepGainUsd }}/year</div>
                      <div>{{ aepGainEnergy }} MWh/year</div>
                    </div>
                    <div v-else>
                      <div>+ ${{ cumulativeGainUsd }}</div>
                      <div>{{ cumulativeGainEnergy }} MWh</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </AtAGlance>
        </div>
      </div>
      <div class="grid1-row-2-col-1 d-flex">
        <div class="plot-card d-flex flex-column pa-4 event-plot-container">
          <span class="plot-heading"
            >Top reliability health events (90 days)</span
          >
          <div
            class="d-flex justify-center align-center event-plot-container__spinner"
            v-if="loadingEventData"
          >
            <v-progress-circular
              :size="40"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <Plotly
            v-else
            :data="eventDataForPlotly.data"
            :layout="eventDataForPlotly.layout"
            class="figure-container"
          ></Plotly>
        </div>
        <div
          class="plot-card d-flex flex-column justify-content-between pa-4 performance-plot-container"
        >
          <span class="plot-heading">Top performance issues</span>
          <div
            class="d-flex justify-center align-center performance-plot-container__spinner"
            v-if="performanceDataLoading"
          >
            <v-progress-circular
              :size="40"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <Plotly
            v-else
            :data="performanceDataForPlotly.data"
            :layout="performanceDataForPlotly.layout"
            class="figure-container"
          ></Plotly>
        </div>
      </div>
      <div class="grid1-row-2-col-2">
        <div class="d-flex justify-space-between pt-4 px-4">
          <span v-if="!siteOemToggle" class="plot-heading">Sites</span>
          <span v-else class="plot-heading">OEMs</span>
          <div class="d-flex switch-labels">
            <label class="mr-3">Site</label>
            <v-switch
              inset
              hide-details
              dense
              v-model="siteOemToggle"
              class="toggle ma-0 pa-0"
              style="transform: scale(0.75)"
            ></v-switch>
            <label>OEM</label>
          </div>
        </div>
        <div v-if="!siteOemToggle" class="sites-table">
          <div class="pl-4 pr-4">
            <p class="ma-0">
              Overview of opportunity and issues on a site level. Click critical
              issues to get started on addressing open items.
            </p>
          </div>
          <DynamicTable
            :headers="siteOverviewHeaders"
            :items="siteOverviewDataSorted"
            :loading="loading.getIssuesGroupedBySite"
            :hasFooter="true"
            :rowsPerPage="10"
            :disableSearch="true"
            pageType="home"
            tableType="site"
          >
            <template v-slot:name="{ item }">
              <div>
                <router-link
                  :to="{
                    name: `NewSiteDashboard`,
                    params: {
                      siteId: item.id,
                    },
                  }"
                  class="link-cell"
                >
                  <div class="d-flex justify-space-between">
                    <div class="link-text">{{ item.name }}</div>
                    <div class="mr-1 pointer">
                      <v-icon size="1.25rem">mdi-open-in-new</v-icon>
                    </div>
                  </div>
                </router-link>
              </div>
            </template>
          </DynamicTable>
        </div>
        <div v-else class="sites-table">
          <div class="pl-4">
            <p class="ma-0">
              Overview of opportunity and issues on an OEM level. Click critical
              issues to get started on addressing open items.
            </p>
          </div>
          <DynamicTable
            :headers="oemOverviewHeaders"
            :items="oemOverviewDataSorted"
            :loading="loading.getIssuesGroupedByOEM"
            :hasFooter="true"
            :rowsPerPage="10"
            :disableSearch="true"
            pageType="home"
            tableType="oem"
          >
          </DynamicTable>
        </div>
      </div>
    </div>
    <div class="map-container">
      <MapView
        :mapData="mapConfig"
        height="450px"
        :isMapLoading="loading.getIssuesGroupedBySite"
      />
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axiosAPI";
import { mapState, mapActions } from "vuex";
import Plotly from "@/components/Plotly";
import AtAGlance from "@/components/SummaryCards/AtAGlance";
import HealthWidget from "@/components/SummaryCards/HealthWidget";
import MapView from "@/components/MapView";
import DynamicTable from "@/components/DynamicTable.vue";
import {
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  clickCountLimit,
  informationIcon,
} from "@/helpers/variables";
import { roundToString, roundAepPct, setUrlParams } from "@/helpers/functions";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { COLORS } from "../helpers/colors";

export default {
  name: "GlobalDashboard",
  components: {
    MapView,
    AtAGlance,
    HealthWidget,
    Plotly,
    DynamicTable,
  },
  data() {
    return {
      isForceRefresh: false,
      setUrlParams,
      informationIcon,
      issuesOverTimeData: null,
      loadingIssuesOverTimeData: false,
      toggle: true,
      roundToString,
      issuesByComponent: [],
      loadingIssuesByComponent: false,
      lossGainToggle: true,
      siteOemToggle: false,
      siteOverviewHeaders: [
        {
          id: 1,
          text: "SITE",
          value: "name",
        },
        {
          id: 2,
          text: "AEP",
          value: "aep_pct",
        },
        {
          id: 3,
          text: "CRITICAL ISSUES",
          value: "critical_issues",
        },
        {
          id: 4,
          text: "$/MWh",
          value: "usd_mwh",
        },
      ],
      oemOverviewHeaders: [
        {
          id: 1,
          text: "OEM",
          value: "name",
        },
        {
          id: 2,
          text: "AEP",
          value: "aep_pct",
        },
        {
          id: 3,
          text: "CRITICAL ISSUES",
          value: "critical_issues",
        },
        {
          id: 4,
          text: "$/MWh",
          value: "usd_mwh",
        },
      ],
      eventData: [],
      loadingEventData: false,
      performanceDataLoading: true,
      performanceData: [],
      performanceDataForPlotly: {},
    };
  },
  computed: {
    ...mapState({
      allIssues: (state) => state.issues.allIssues,
      paginatedIssuesForIssuesTable: (state) =>
        state.home.paginatedIssuesForIssuesTable,
      issuesTotal: (state) => state.home.issuesTotal,
      summary: (state) => state.home.summary,
      checks: (state) => state.home.checks,
      issuesGroupedByIssue: (state) => state.home.issuesGroupedByIssue,
      issuesGroupedByOrg: (state) => state.home.issuesGroupedByOrg,
      issuesGroupedBySite: (state) => state.home.issuesGroupedBySite,
      issuesGroupedByOEM: (state) => state.home.issuesGroupedByOEM,
      clickCount: (state) => state.app.clickCount,
      loading: (state) => state.home.loading,
    }),
    siteTableData() {
      if (this.issuesGroupedBySite?.length > 0) {
        return this.issuesGroupedBySite.map((item) => ({
          ...item,
          selected: false,
          site_id: item.farm_id ? item.farm_id : item.id,
          site_name: item.farm_name ? item.farm_name : item.site_name,
          n_open: item.n_open ? item.n_open : 0,
          n_req_attn: item.n_require_external_input
            ? item.n_require_external_input
            : 0,
          subscription: item.sow_def_name
            ? item.sow_def_name
            : item.subscription,
          color: item.n_require_external_input > 0 ? COLORS.red : COLORS.blue,
          position: L.latLng(item.latitude_deg, item.longitude_deg),
          mapInfoTitle: item.farm_name ? item.farm_name : item.site_name,
          mapInfoDesc: `Open issues: ${item.n_open ? item.n_open : 0}`,
          mapInfoRoute: `/sites/${item.farm_id ? item.farm_id : item.id}`,
        }));
      } else {
        return [];
      }
    },
    siteOverviewData() {
      if (this.issuesGroupedBySite?.length > 0) {
        return this.issuesGroupedBySite.map((item) => ({
          id: item.farm_id,
          name: item.farm_name ? item.farm_name : item.site_name,
          aep_pct: item.aep_gain_pct
            ? roundToString(item.aep_gain_pct, 2) + `%`
            : "0%",
          critical_issues: String(item.n_open) ?? "0",
          usd_mwh: item.latest_power_price_upper_usd
            ? `$` + item.latest_power_price_upper_usd
            : `$` + 0,
        }));
      }
      return [];
    },
    siteOverviewDataSorted() {
      return this.sortDataByTwoCriteria(
        this.siteOverviewData,
        "critical_issues",
        "usd_mwh",
      );
    },
    oemOverviewData() {
      if (this.issuesGroupedByOEM?.length > 0) {
        return this.issuesGroupedByOEM.map((item) => ({
          name: item.manufacturer_name,
          aep_pct: item.aep_gain_pct
            ? roundToString(item.aep_gain_pct, 2) + `%`
            : "0%",
          critical_issues: String(item.n_open) ?? "0",
          usd_mwh: item.latest_power_price_upper_usd
            ? `$` + item.latest_power_price_upper_usd
            : `$` + 0,
        }));
      }
      return [];
    },
    oemOverviewDataSorted() {
      return this.sortDataByTwoCriteria(
        this.oemOverviewData,
        "critical_issues",
        "usd_mwh",
      );
    },
    mapConfig() {
      return {
        zoom: 10,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
        markers: this.siteTableData,
      };
    },
    nOpenReliablilityIssues() {
      return this.summary?.n_open_reliability ?? -1;
    },
    nOpenPerformanceIssues() {
      return this.summary?.n_open_performance ?? -1;
    },
    nSites() {
      return roundToString(this.summary?.n_sites, 0) ?? 0;
    },
    nTurbines() {
      return roundToString(this.summary?.n_turbines, 0) ?? 0;
    },
    totalCapacityMw() {
      return roundToString(this.summary?.total_capacity_mw) ?? 0;
    },
    aepPotentialUsd() {
      return roundToString(this.summary?.aep_loss_upper_usdpyr_open, -1) ?? 0;
    },
    aepGainUsd() {
      return (
        roundToString(
          this.summary?.aep_gain_upper_usdpyr_fixed_or_resolved,
          -1,
        ) ?? 0
      );
    },
    aepGainEnergy() {
      return (
        roundToString(this.summary?.aep_gain_mwhpyr_fixed_or_resolved, -1) ?? 0
      );
    },
    cumulativeGainUsd() {
      return (
        roundToString(this.summary?.gain_upper_usd_fixed_or_resolved, -1) ?? 0
      );
    },
    cumulativeGainEnergy() {
      return roundToString(this.summary?.gain_mwh_fixed_or_resolved, -1) ?? 0;
    },
    impactColor() {
      return "gain";
    },
    eventDataForPlotly() {
      let formattedEventData = this.eventData.map((item) => ({
        ...item,
        total_duration_days: item.total_duration_s / 86400, // seconds in day
      }));
      let sortedData = [...formattedEventData].sort(
        (a, b) => a.total_duration_days - b.total_duration_days,
      );
      let x = sortedData.map((item) => item.total_duration_days);
      let y = sortedData.map((item) => this.customWrap(item.message, 20));
      let noData = x.length === 0;
      const plotlyData = {
        data: [
          {
            x: x,
            y: y,
            type: "bar",
            orientation: "h",
            width: 0.5,
          },
        ],
        layout: {
          margin: { l: noData ? 20 : 150, r: 10, t: 20, b: 40, pad: 4 },
          modebar: {
            bgcolor: `rgba(0,0,0,0)`,
            color: this.$vuetify.theme.isDark
              ? `rgba(255,255,255,0.5)`
              : `rgba(0,0,0,0.3)`,
            activecolor: this.$vuetify.theme.isDark
              ? `rgba(255,255,255,0.7)`
              : `rgba(0,0,0,0.7)`,
          },
          paper_bgcolor: this.$vuetify.theme.isDark ? "#121212" : "transparent",
          plot_bgcolor: this.$vuetify.theme.isDark ? "#121212" : "transparent",
          yaxis: {
            gridcolor: this.$vuetify.theme.isDark
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)",
            zerolinecolor: this.$vuetify.theme.isDark
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)",
          },
          xaxis: {
            title: "Cumulative duration (days)",
            gridcolor: this.$vuetify.theme.isDark
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)",
            zerolinecolor: this.$vuetify.theme.isDark
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)",
            linecolor: this.$vuetify.theme.isDark
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)",
          },
          font: {
            color: this.$vuetify.theme.isDark ? "#c9d1d9" : "",
          },
        },
      };
      return plotlyData;
    },
  },
  methods: {
    ...mapActions({
      getIssues: "issues/getIssues",
      getPaginatedIssuesForIssuesTable: "home/getPaginatedIssuesForIssuesTable",
      getChecks: "home/getChecks",
      getIssuesGroupedByIssue: "home/getIssuesGroupedByIssue",
      getIssuesGroupedByOrg: "home/getIssuesGroupedByOrg",
      getIssuesGroupedBySite: "home/getIssuesGroupedBySite",
      getIssuesGroupedByOEM: "home/getIssuesGroupedByOEM",
      getSummary: "home/getSummary",
      getUserNotifications: "notifications/getUserNotifications",
      incrementClickCount: "app/incrementClickCount",
      handleAxiosError: "error/raiseError",
    }),
    customWrap(text, maxLength) {
      let words = text.split(" ");
      let wrappedText = "";
      let currentLine = "";
      words.forEach((word) => {
        if ((currentLine + word).length > maxLength) {
          if (currentLine.length > 0) {
            wrappedText += currentLine + "<br>";
            currentLine = "";
          }
          if (word.length > maxLength) {
            wrappedText += word + "<br>";
          } else {
            currentLine = word;
          }
        } else {
          currentLine += (currentLine.length > 0 ? " " : "") + word;
        }
      });
      if (currentLine.length > 0) {
        wrappedText += currentLine;
      }
      return wrappedText;
    },
    sortDataByTwoCriteria(data, primaryField, secondaryField) {
      return [...data].sort((a, b) => {
        let aPrimary = parseInt(a[primaryField]);
        let bPrimary = parseInt(b[primaryField]);
        if (aPrimary === bPrimary) {
          let aSecondary = parseFloat(a[secondaryField].replace(/[$,]/g, ""));
          let bSecondary = parseFloat(b[secondaryField].replace(/[$,]/g, ""));
          return bSecondary - aSecondary;
        }
        return bPrimary - aPrimary;
      });
    },
    // Call APIs here
    async fetchEventData() {
      this.loadingEventData = true;
      try {
        const res = await axios.get("/top-events");

        if (res.status === 200) {
          this.eventData = res.data;
        } else {
          const error = {
            name: "getTopEvents",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        const err = {
          name: "getTopEvents",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingEventData = false;
    },
    async fetchPerformanceData() {
      this.performanceDataLoading = true;
      if (this.issuesGroupedByIssue?.length > 0) {
        let sortedData = [...this.issuesGroupedByIssue] // Create a shallow copy to sort without altering original array
          .sort(
            (a, b) =>
              b.aep_loss_upper_usdpyr_open - a.aep_loss_upper_usdpyr_open,
          ); // Sort in descending order
        let mappedData = sortedData.map((item) => ({
          name: item.name,
          aep_loss: item.aep_loss_upper_usdpyr_open,
        }));
        let filteredData = mappedData.filter((item) => item.aep_loss !== 0);
        if (filteredData.length === 0) {
          this.performanceData = [];
        }
        this.performanceData = filteredData.slice(0, 5); // Return top 5 items based on aep_loss
        this.setPerformanceData();
      } else {
        this.performanceData = [];
      }
      this.performanceDataLoading = false;
    },
    setPerformanceData() {
      let data = this.performanceData;
      let x = [];
      let y = [];
      let noData = false;
      if (data.length > 0) {
        let sortedData = [...data].sort((a, b) => a.aep_loss - b.aep_loss);
        x = sortedData.map((d) => d.aep_loss);
        y = sortedData.map((d) => this.customWrap(d.name, 20));
      } else {
        noData = true;
      }
      this.performanceDataForPlotly = {
        data: [
          {
            x: x,
            y: y,
            type: "bar",
            orientation: "h",
            width: 0.5,
            text: x.map((v) => `-$${this.roundToString(v, 0)}`),
            textposition: "outside",
          },
        ],
        layout: {
          margin: {
            l: noData ? 20 : 150,
            r: 10,
            b: 20,
            t: 20,
            pad: 4,
          },
          xaxis: {
            automargin: true,
            range: [0, Math.max(...x) * 1.3],
          },
          autosize: true,
        },
      };
    },
    async callInitialAPIs(refresh) {
      // Call for site table data to populate map
      if (
        (this.issuesGroupedBySite?.length === 0 && !refresh) ||
        (this.issuesGroupedBySite?.length > 0 && refresh)
      ) {
        this.getIssuesGroupedBySite();
      }
      this.getIssuesGroupedByOEM();
      this.fetchEventData();
      // Data for dashboard top widget cards
      if (this.summary && "n_unconfirmed" in this.summary && !refresh) {
        // pass
      } else {
        this.getSummary();
      }
      await this.getIssuesGroupedByIssue();
      await this.fetchPerformanceData();
    },
  },
  beforeMount() {
    this.callInitialAPIs();
  },
  mounted() {
    const url = new URL(window.location.href);
    if (url.hash) {
      this.setTabByUrl(url.hash);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.grid1 {
  display: grid;
  grid-template-columns: 0.65fr 0.65fr 1fr;
  grid-template-rows: 230px auto auto;
  gap: 0.5rem;
}
.grid1-row-1-col-1 {
  grid-column: 1 / 2;
  border-right: 1px solid var(--v-border-base);
  padding-right: 0.5rem;
  padding-top: 0.45rem;
}
.grid1-row-1-col-2 {
  grid-column: 2 / 3;
  border-right: 1px solid var(--v-border-base);
  padding-right: 0.5rem;
  padding-top: 0.45rem;
}
.grid1-row-1-col-3 {
  grid-column: 3 / 4;
}
.grid1-row-2-col-1 {
  grid-column: 1 / 3;
  border-radius: 8px;
  height: 700px;
  flex-direction: column;
  gap: 0.5rem;
}
.grid1-row-2-col-2 {
  grid-column: 3 / 4;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  min-height: 700px;
}
.grid1-row-3 {
  grid-column: 1 / 4;
}

.org-dashboard {
  &__subtitle {
    p {
      font:
        0.75rem "Museo Sans Rounded",
        sans-serif;
      color: var(--v-text-base);
      font-weight: 600;
    }
  }

  &__header {
    &__help {
      color: var(--v-black3-base);
      box-sizing: border-box;
      padding: 2px 16px 8px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
      .v-icon:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.site-condition-cards {
  height: 100%;

  .availability-card {
    height: 100%;
    min-width: 200px;
  }
  .performance-card {
    height: 100%;
    min-width: 200px;
  }
}
.event-plot-container {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
}
.performance-plot-container {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
}

.at-a-glance-container {
  position: relative;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.plot-figure-container {
  position: relative;
  padding: 0 0 0.55rem 0;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}
.figure-container {
  height: 80%;
  width: 100%;
}

.plot-card {
  height: 50%;
}
.content-container {
  position: relative;
  height: 120px;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.content-body {
  font:
    1.75rem Inter,
    sans-serif;
}

.gain {
  color: $green;
}
.loss {
  color: $red;
}

.sites-table,
.oems-table {
  height: 87%;
}

.sites-link {
  color: var(--v-black1-base);
  text-decoration: none;
}
.sites-link:hover {
  color: var(--v-primary-base);
  text-decoration: underline;
  .v-icon {
    color: var(--v-primary-base) !important;
  }
}

.map-container {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 1264px) {
  .grid1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 230px 230px auto auto auto;
    gap: 0.5rem;
  }
  .grid1-row-1-col-1 {
    grid-column: 1 / 2;
    border-right: none;
    padding-right: 0;
    margin-left: 0.75rem;
  }
  .grid1-row-1-col-2 {
    grid-column: 2 / 3;
    border-right: none;
    border-left: 1px solid var(--v-border-base);
    padding-right: 0.25rem;
    padding-left: 0.5rem;
  }
  .grid1-row-1-col-3 {
    grid-column: 1 / 3;
  }
  .grid1-row-2-col-1 {
    grid-column: 1 / 3;
    border-radius: 8px;
    height: 700px;
    flex-direction: column;
    gap: 0.5rem;
  }
  .grid1-row-2-col-2 {
    grid-column: 1 / 3;
    border: 1px solid var(--v-border-base);
    border-radius: 8px;
  }
  .grid1-row-3 {
    grid-column: 1 / 3;
  }

  .sites-table,
  .oems-table {
    height: 90%;
  }
}
</style>
